import { Link, graphql } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd5';

import { BasicContainer, SectionBody, SectionTitle, SectionTitleSm, SectionWrapper } from './basic-styles';

import { HsfLandingObject, LandingSection } from '../types/cms/models/hsf-landing';
import { Button } from '../components';
import PageHeader from '../components/common/page-header';
import PublicLayout from '../layout/public-layout';
import { programShortName } from '../siteContent';
import theme from '../theme';
import { useI18NContext } from '../i18n';

type HsfLandingProps = {
  data: {
    navigationItem: any;
    hsfLanding: HsfLandingObject;
    landingSections: LandingSection;
  };
};

export const HsfLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-top: 15px;
    max-width: 300px;
  }
`;

export const HsfTitleWrapper = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: #2b2b2b;
  @media (max-width: ${theme.screenMd}) {
    padding: 0 24px;
  }
`;

export const HsfBottomSection = styled.div`
  background-color: #f5f5f5;
  margin: 0 -50%;
  padding: 5% 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: ${theme.screenMd}) {
    padding: 20px 24px;
    margin: 0 0 !important;
  }
`;

export const CTAWrapper = styled.div`
  margin-top: 45px;
  @media (max-width: ${theme.screenMd}) {
    margin-top: 10px;
  }
  .ant-btn {
    min-width: 165px;
  }
  .ant-typography {
    color: ${theme.colorsWhite};
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const pageQuery = graphql`
  query ($language: String) {
    navigationItem: allDirectusHsfLandingType(filter: { status: { eq: "published" } }) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
      }
    }

    hsfLanding: allDirectusHsfLanding(filter: { status: { eq: "published" } }) {
      nodes {
        hsf_landing_logo {
          data {
            full_url
          }
        }
        translation(language: $language) {
          hsf_bottom_section_title
          hsf_bottom_section_description
        }
      }
    }

    landingSections: allDirectusHsfLandingSection(filter: { status: { eq: "published" } }) {
      nodes {
        translation(language: $language) {
          landing_section_title
          landing_section_body
        }
      }
    }
  }
`;

const HsfLanding = ({ data: { hsfLanding, landingSections, navigationItem } }: HsfLandingProps) => {
  const { translateSlug } = useI18NContext();
  return (
    <PublicLayout seoTitle={`${programShortName} University Landing`}>
      {hsfLanding && hsfLanding.nodes.length ? (
        <BasicContainer>
          <PageHeader
            title=""
            breadcrumb={[
              <Link key="1" to={translateSlug('/')}>
                Home
              </Link>,
              `Resources: ${navigationItem?.nodes[0].translation?.value}`,
            ]}
            fullWidth
            isResponsive
            noShadow
          />
          <HsfLogoWrapper>
            {hsfLanding.nodes[0].hsf_landing_logo.data.full_url ? (
              <img alt="hsfLogo" src={hsfLanding.nodes[0].hsf_landing_logo?.data.full_url} />
            ) : (
              ''
            )}
          </HsfLogoWrapper>
          <HsfTitleWrapper>{navigationItem?.nodes[0].translation?.value}</HsfTitleWrapper>
          {landingSections.nodes &&
            landingSections.nodes.map((section: any, index: number) => {
              return (
                <SectionWrapper key={index}>
                  <SectionTitle>
                    {section.translation?.landing_section_title ? section.translation?.landing_section_title : ''}
                  </SectionTitle>
                  <SectionBody
                    dangerouslySetInnerHTML={{
                      __html: section.translation?.landing_section_body
                        ? section.translation?.landing_section_body
                        : '',
                    }}
                  />
                </SectionWrapper>
              );
            })}
          <HsfBottomSection>
            <SectionTitleSm>{hsfLanding.nodes[0].translation?.hsf_bottom_section_title}</SectionTitleSm>
            <SectionBody>{hsfLanding.nodes[0].translation?.hsf_bottom_section_description}</SectionBody>
            <CTAWrapper>
              <Button type="primary-blue" href="/login">
                <Typography>Sign In</Typography>
              </Button>
            </CTAWrapper>
          </HsfBottomSection>
        </BasicContainer>
      ) : (
        <div />
      )}
    </PublicLayout>
  );
};

export default HsfLanding;
